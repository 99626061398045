import React from "react";
import AirGreenland from "../../assets/client-ag.svg";
import DHL from "../../assets/client-dhl.svg";
import Lot from "../../assets/client-lot.svg";
import Tag from "../../assets/client-tag.svg";
import Tasman from "../../assets/client-tasman.svg";
import WestAtlantic from "../../assets/client-wa.svg";
import Catreus from "../../assets/new-catreus-logo.svg";
import styled from "styled-components";
import styles from "./trustedBy.module.less";
import ScrollAnimation from "react-animate-on-scroll";

const operators = [AirGreenland, DHL, Lot, Tag, Tasman, WestAtlantic];

const operatorImages = operators.map((operator, key) => (
  <ScrollAnimation animateOnce animateIn="fadeInUp" delay={key * 75} offset={0}>
    <img
      key={operator}
      src={operator}
      alt={`${operator}`}
      className={key === 0 && styles.logoCorrection}
    />
  </ScrollAnimation>
));

const OperatorDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #eeeff3;
  padding: 25px 0;

  align-items: center;
  h4 {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.04px;
    color: #5e626c;
    margin-bottom: 0;
  }
  @media screen and (max-width: 576px) {
    h4 {
      display: none;
    }
  }
`;

export default () => (
  <OperatorDiv className={styles.operatorsParent}>
    <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
      <h4>TRUSTED BY:</h4>
    </ScrollAnimation>
    {operatorImages}
  </OperatorDiv>
);
