import React from "react";
import Tech from "../../assets/product-tech-log.svg";
import Task from "../../assets/product-task-cards.svg";
import Logbook from "../../assets/product-digital-engine-logbook.svg";
import JAR from "../../assets/product-aircraft-registry.svg";
import CAMO from "../../assets/product-camo-services.svg";
import MEL from "../../assets/product-mel-manager.svg";
import CentrikLogo from "../../assets/product-centrik.svg";
import DisplayCard from "./displayCard";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";
import Centrik from "../../pages/centrik";

const OptionDiv = styled.div`
  font-family: "Open Sans";
  text-align: center;
  padding: 20px 0px;
`;

const OptionHeading = styled.div`
  padding-bottom: 20px;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.03px;
  text-align: center;
  color: #242d41;
  @media screen and (max-width: 1200px) {
    font-size: 20px;
  }
  @media screen and (max-width: 576px) {
    font-size: 18px;
  }
`;

const OptionText = styled.div`
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: 0.03px;
  text-align: center;
  color: rgba(36, 45, 65, 0.7);
  padding: 0 40px;
  margin-bottom: 25px;
  @media screen and (max-width: 1200px) {
    font-size: 16px;
  }
  @media screen and (max-width: 576px) {
    font-size: 14px;
    padding: 0;
  }
`;

const DisplayCardsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const TechCard = {
  img: Tech,
  title: "Electronic Tech Log",
  text:
    "Automated flight entry, intuitive defect recording and fully integrated with your existing scheduling and maintenance systems. Gain a real-time view of your aircraft's status and utilisation.",
  ticks: ["Reduce cost", "Increase compliance", "Gain real-time data"],
  link: "techlog",
};

const TaskCard = {
  img: Task,
  title: "Electronic Task Cards",
  text:
    "Conduct maintenance the modern way with real-time analytics, interactive manuals and records created automatically. Avoid the hours spent on writing up tasks.",
  ticks: [
    "Streamlined task tracking",
    "Electronic sign off",
    "Accurate technical manuals",
  ],
  link: "taskcards",
};

const LogbookCard = {
  img: Logbook,
  title: "Digital Engine Log",
  text:
    "The single-source of trusted flight and maintenance data associated with your aircraft. Standardised, secure and controlled by you, we make it easy to share data with the systems and stakeholders that require it.",
  ticks: [
    "Fully validated records",
    "Traceable part history",
    "Automated analytics",
  ],
  link: "logbook",
};

const CamoCard = {
  img: CAMO,
  title: "CAMO Services",
  text:
    "A fully digital CAMO combining technical expertise with the latest technologies to provide the most efficient and cost-effective way to manage the airworthiness of your aircraft.",
  ticks: [
    "EASA Part-M approved",
    "Full airworthiness management",
    "Experienced team",
  ],
  link: "camo",
};

const MelCard = {
  img: MEL,
  title: "MEL Manager",
  text:
    "Issue and revise MELs with a few clicks. No need to deal with formatting or revisions, the MEL Manager makes it trivial to keep on top of your MELs.",
  ticks: ["Increase compliance", "Cut time overhead", "Simple to use"],
  link: "mel-manager",
};

const CentrikCard = {
  img: CentrikLogo,
  title: "Centrik",
  text:
    "Simple, intuitive operational management. Real-time integrated management of your operations.",
  ticks: ["Enhance efficiency", "Boost safety", "Ensure regulatory compliance"],
  link: "centrik",
};

const RegistryCard = {
  img: JAR,
  title: "Aircraft Registry",
  text:
    "The world's first digital aircraft registry enabling fast and efficient registration and oversight of your aircraft. Our systems enable near-instant processing of applications, approvals and superior levels of oversight all whilst reducing the burden on aircraft owners and operators.",
  ticks: [
    "Fully digital aircraft registry",
    "Pre-registration available",
    "Simple and easy online process",
  ],
  link: "registry",
};

const headingTexts = {
  operators: {
    heading: "TrustFlight for Aircraft Operators",
    text:
      "The TrustFlight suite powers a connected workflow for your entire operation. This includes products to enable a transition away from paper, as well as services to help boost efficiency and reduce risk.",
  },
  mro: {
    heading: "TrustFlight for MROs",
    text:
      "As a Maintenance and Repair Organisation, our Electronic Task Cards software, CAMO services, and Digital Engine Log will help you achieve new levels of productivity, quality and insight into your operations.",
  },
  regulators: {
    heading: "TrustFlight for Regulators",
    text:
      "Using our digital Aircraft Registry and Digital Engine Log systems, we enable a new era of continuous oversight and real-time data to achieve greater levels of safety whilst reducing regulatory burden.",
  },
  companies: {
    heading: "TrustFlight for Leasing Companies",
    text:
      "As an aircraft lessor, effortlessly register your assets with our blockchain-based Aircraft Registry, leverage our CAMO service for inspections & technical support, and access data insights with the Digital Engine Log.",
  },
};

export default ({ choice }) => {
  let cardsArray = [];
  switch (choice) {
    case "operators":
      cardsArray = [
        TechCard,
        TaskCard,
        CamoCard,
        MelCard,
        LogbookCard,
        CentrikCard,
      ];
      break;
    case "mro":
      cardsArray = [TaskCard, CamoCard, LogbookCard, CentrikCard];
      break;
    case "regulators":
      cardsArray = [MelCard, LogbookCard, CentrikCard];
      break;
    case "companies":
      cardsArray = [CamoCard, LogbookCard];
      break;
    default:
      cardsArray = [];
  }

  const cards = cardsArray.map((card, key) => (
    <ScrollAnimation
      animateOnce
      animateIn="fadeIn"
      delay={key * 100}
      offset={0}
    >
      <DisplayCard
        key={card.title}
        img={card.img}
        title={card.title}
        text={card.text}
        ticks={card.ticks}
        link={card.link}
      />
    </ScrollAnimation>
  ));

  return (
    <OptionDiv>
      <div>
        <ScrollAnimation animateOnce animateIn="fadeIn" offset={0} delay={200}>
          <OptionHeading>{headingTexts[choice].heading}</OptionHeading>
          <OptionText>{headingTexts[choice].text}</OptionText>
        </ScrollAnimation>
        <DisplayCardsDiv>{cards}</DisplayCardsDiv>
      </div>
    </OptionDiv>
  );
};
