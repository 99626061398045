import React, { Component, Fragment } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import goArrow from "../assets/icon-arrow-electric.svg";
import playIcon from "../assets/Landing/play-icon.svg";
import ArrowRight from '../assets/arrow-right.svg';
import ArrowLeft from '../assets/arrow-left.svg';
import ModalVideo from "react-modal-video";
import Img from "gatsby-image";
import styles from "./pageHeaderVideo.module.less";
import * as Scroll from "react-scroll";
import ScrollAnimation from "react-animate-on-scroll";

const { scroller } = Scroll;

export default class pageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  render() {
    const {
      link,
      linkText,
      bigHeader,
      bigImage,
      useBgImage,
      videoLink,
      mediumHeader,
      bgImg,
      imageWithShadow,
      longTag,
      videoCaroselNumber,
      handleVideoCaroselButtonClick
    } = this.props;
    return (
      <div
        className={`${styles.pageHeader} ${bigHeader &&
          styles.bigHeader} ${useBgImage &&
          styles.pageHeaderBg} ${mediumHeader &&
          styles.pageHeaderMedium} ${longTag && styles.longTag}`}
        style={
          bgImg && {
            backgroundImage: `url(${bgImg.childImageSharp.fluid.src})`
          }
        }
      >
        <Container>
          <Row>
            <Fragment>
              <Col md={6} className={styles.desktop}>
                <div className={styles.videoWrapper}>
                <ModalVideo
                  channel="vimeo"
                  isOpen={this.state.isOpen}
                  videoId={videoLink}
                  onClose={() => this.setState({ isOpen: false })}
                />
                <ScrollAnimation animateOnce animateIn="fadeIn" offset={0}>
                  <div className={styles.headerImage}>
                    {videoLink && (
                      <div className={styles.playIcon}>
                        <div className={styles.bigPictureLink}>
                          <button
                            onClick={e => this.setState({ isOpen: true })}
                            className={styles.buttonPlay}
                          >
                            <img src={playIcon} alt="playIcon" />
                          </button>
                        </div>
                      </div>
                    )}
                    <Carousel controls={false} indicators={false} interval={null} activeIndex={videoCaroselNumber} fade={true}>
                      <Carousel.Item>
                        <Img
                          fluid={bigImage[0]}
                          className={imageWithShadow && styles.imageWithShadow}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <Img
                          fluid={bigImage[1]}
                          className={imageWithShadow && styles.imageWithShadow}
                        />
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </ScrollAnimation>
                </div>
              </Col>
              <Col md={6} className={`${styles.headerTextCol} ${styles.desktop}`}>
                <ScrollAnimation animateOnce animateIn="fadeIn" offset={0}>
                  <div className={styles.flexParent}>
                    <div className={styles.buttonWrapper}>
                      <div className={styles.leftbuttonWrapper}>
                        <button className={`${styles.arrowBox} ${videoCaroselNumber === 0 && styles.arrowBoxOpacity}`} onClick={() => handleVideoCaroselButtonClick('left')}><img src={ArrowLeft} alt="Arrow" /></button>
                      </div>
                      <div className={styles.rightButtonWrapper}>
                        <button className={`${styles.arrowBox} ${videoCaroselNumber === 1 && styles.arrowBoxOpacity}`} onClick={() => handleVideoCaroselButtonClick('right')}><img src={ArrowRight} alt="Arrow" className={styles.rightArrow} /></button>
                      </div>
                    </div>
                    <div className={styles.textWrapper}>
                      <div className={styles.tagDiv}>
                        {this.props.tag && (
                          <div className={styles.headerTag}>
                            {this.props.tagStrong && (
                              <span className={styles.headerTagStrong}>
                                {this.props.tagStrong}
                              </span>
                            )}
                            <span className={styles.headerTagNormal}>
                              {this.props.tag}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className={styles.headerDiv}>
                        <h1
                          className={styles.header}
                          dangerouslySetInnerHTML={{
                            __html: this.props.header
                          }}
                        />
                      </div>
                      <div className={styles.subHeaderDiv}>
                        <h2
                          className={styles.subHeader}
                          dangerouslySetInnerHTML={{
                            __html: this.props.subHeader
                          }}
                        />
                      </div>
                      {link && linkText && (
                        <Row>
                          <Col className={styles.actionLinkHolder}>
                            <Link
                              to={link}
                              className={`${styles.actionLink} ${this.props
                                .whiteLink && styles.actionLinkWhite}`}
                            >
                              {linkText}
                              <span>
                                <img src={goArrow} alt="goArrow" />
                              </span>
                            </Link>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </div>
                </ScrollAnimation>
              </Col>
              <Col md={12} className={`${styles.headerTextCol} ${styles.mobile}`}>
                <ScrollAnimation animateOnce animateIn="fadeIn" offset={0}>
                  <div className={styles.flexParent}>
                    <div className={styles.textWrapper}>
                      <div className={styles.tagDiv}>
                        {this.props.tag && (
                          <div className={styles.headerTag}>
                            {this.props.tagStrong && (
                              <span className={styles.headerTagStrong}>
                                {this.props.tagStrong}
                              </span>
                            )}
                            <span className={styles.headerTagNormal}>
                              {this.props.tag}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className={styles.headerDiv}>
                        <h1
                          className={styles.header}
                          dangerouslySetInnerHTML={{
                            __html: this.props.header
                          }}
                        />
                      </div>
                      <div className={styles.subHeaderDiv}>
                        <h2
                          className={styles.subHeader}
                          dangerouslySetInnerHTML={{
                            __html: this.props.subHeader
                          }}
                        />
                      </div>
                      {link && linkText && (
                        <Row>
                          <Col className={styles.actionLinkHolder}>
                            <Link
                              to={link}
                              className={`${styles.actionLink} ${this.props
                                .whiteLink && styles.actionLinkWhite}`}
                            >
                              {linkText}
                              <span>
                                <img src={goArrow} alt="goArrow" />
                              </span>
                            </Link>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </div>
                </ScrollAnimation>
              </Col>
              <Col md={12} className={styles.mobile}>
                <div className={styles.videoWrapper}>
                <ModalVideo
                  channel="vimeo"
                  isOpen={this.state.isOpen}
                  videoId={videoLink}
                  onClose={() => this.setState({ isOpen: false })}
                />
                <ScrollAnimation animateOnce animateIn="fadeIn" offset={0}>
                  <div className={styles.headerImage}>
                    {videoLink && (
                      <div className={styles.playIcon}>
                        <div className={styles.bigPictureLink}>
                          <button
                            onClick={e => this.setState({ isOpen: true })}
                            className={styles.buttonPlay}
                          >
                            <img src={playIcon} alt="playIcon" />
                          </button>
                        </div>
                      </div>
                    )}
                    <Carousel controls={false} indicators={false} interval={null} activeIndex={videoCaroselNumber} fade={true}>
                      <Carousel.Item>
                        <Img
                          fluid={bigImage[0]}
                          className={imageWithShadow && styles.imageWithShadow}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <Img
                          fluid={bigImage[1]}
                          className={imageWithShadow && styles.imageWithShadow}
                        />
                      </Carousel.Item>
                    </Carousel>
                    <div className={styles.buttonWrapper}>
                      <div className={styles.leftButtonWrapper}>
                        <button className={`${styles.arrowBox} ${videoCaroselNumber === 0 && styles.arrowBoxOpacity}`} onClick={() => handleVideoCaroselButtonClick('left')}><img src={ArrowLeft} alt="Arrow" /></button>
                      </div>
                      <div className={styles.rightButtonWrapper}>
                        <button className={`${styles.arrowBox} ${videoCaroselNumber === 1 && styles.arrowBoxOpacity}`} onClick={() => handleVideoCaroselButtonClick('right')}><img src={ArrowRight} alt="Arrow" className={styles.rightArrow} /></button>
                      </div>
                    </div>
                  </div>
                </ScrollAnimation>
                </div>
              </Col>
            </Fragment>
          </Row>
        </Container>
      </div>
    );
  }
}
