import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../components/layout";
import PageHeaderVideo from "../components/pageHeaderVideo";
import DisplayOption from "../components/LandingPage/displayOption";
import Started from "../components/Layout/started";
import TrustedBy from "../components/LandingPage/trustedBy";
import styles from "./index.module.less";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import SEO from "../components/SEO";
import ScrollAnimation from "react-animate-on-scroll";
import GlobalScale from "../components/LandingPage/globalScale";

const TransformerDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
`;

const TransformHeader = styled.h2`
  margin-top: 80px;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.04px;
  text-align: center;
  color: #242d41;
  margin-bottom: 25px;
  @media screen and (max-width: 1200px) {
    font-size: 26px;
  }
  @media screen and (max-width: 991px) {
    font-size: 24px;
    margin-top: 60px;
  }
  @media screen and (max-width: 576px) {
    font-size: 20px;
    margin-top: 40px;
  }
`;

const TransformText = styled.p`
  font-size: 22px;
  letter-spacing: 0.03px;
  text-align: center;
  color: rgba(36, 45, 65, 0.7);
  margin-bottom: 60px;
  @media screen and (max-width: 1200px) {
    font-size: 20px;
  }
  @media screen and (max-width: 991px) {
    font-size: 18px;
  }
  @media screen and (max-width: 576px) {
    font-size: 16px;
  }
`;

const TabSection = styled.div`
  width: 100%;
  background-image: linear-gradient(to top, #f0f5f9, #ffffff 100%);
  padding-bottom: 60px;
  @media screen and (max-width: 400px) {
    padding: 0;
  }
`;

const ClickableOption = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: rgba(36, 45, 65, 0.7);
  padding: 25px 10px;
  border-bottom: 1px solid #eeeff3;
  cursor: pointer;
  transition: all 0.2s;
  ${(props) => props.selected && "border-bottom: 1px solid #1E5BB5;"}
  ${(props) => props.selected && "color: #1E5BB5;"}
  &:hover {
    color: #1E5BB5;
  }
  @media screen and (max-width: 1200px) {
    font-size: 16px;
  }
  @media screen and (max-width: 991px) {
    font-size: 14px;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
    padding: 15px 10px;
  }
  @media screen and (max-width: 576px) {
    font-size: 14px;
  }
`;

const BlogDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  &:hover {
    .gatsby-image-wrapper {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
    }
  }
  h3 {
    font-size: 16px;
    font-weight: 600;
    color: #242d41;
    text-align: left;
    margin-top: 15px;
  }
  @media screen and (max-width: 991px) {
    h3 {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 768px) {
    h3 {
      font-size: 16px;
      margin-bottom: 20px;
      margin-top: 10px;
    }
  }
`;

export default (props) => {
  const { mainImage, videoImage, jarImage } = props.data;
  const [paneChoice, setPaneChoice] = useState("operators");
  const [videoCaroselNumber, setVideoCaroselNumber] = useState(0);
  const videoLinkArray = ['330066263', '418135852'];
  const headerArray =['The New Standard in Aviation Records', 'The Aircraft Tech Log. Transformed.'];
  const subHeaderArray = ['We are building the tools and services to enable the next generation of Digital Aviation Records.', 'Make the final step to paperless operations. Gain a real-time view of your aircraft status.'];
  const linkText= ['About TrustFlight', 'Discover more'];
  const linkArray =['/about', '/techlog'];
  const videoPictureArray = [mainImage.childImageSharp.fluid, videoImage.childImageSharp.fluid];




  const handleVideoCaroselButtonClick = (direction) => {
    if (direction === 'left') {
      if (videoCaroselNumber === 0) {
        return
      } else {
        setVideoCaroselNumber(videoCaroselNumber - 1);
      }
    }
    if (direction === 'right') {
      if (videoCaroselNumber === 1) {
        return
      } else {
        setVideoCaroselNumber(videoCaroselNumber + 1);
      }
    }
  }

  return (
    <Layout>
      <SEO
        title='The New Standard in Aviation Records'
        description='We are building the tools and services to enable the next generation of Digital Aviation Records'
      />
      <PageHeaderVideo
        header={headerArray[videoCaroselNumber]}
        subHeader={subHeaderArray[videoCaroselNumber]}
        linkText={linkText[videoCaroselNumber]}
        link={linkArray[videoCaroselNumber]}
        bigHeader
        bigImage={videoPictureArray}
        useBgImage
        videoLink={videoLinkArray[videoCaroselNumber]}
        videoCaroselNumber={videoCaroselNumber}
        handleVideoCaroselButtonClick={handleVideoCaroselButtonClick}
      />
      <Container>
        <TrustedBy />
        <Row className='justify-content-md-center'>
          <Col xl={8} lg={10} md={10}>
            <TransformerDiv>
              <ScrollAnimation animateOnce animateIn='fadeIn' offset={0}>
                <TransformHeader>
                  We’re here to help you transform your business
                </TransformHeader>
              </ScrollAnimation>
              <ScrollAnimation
                animateOnce
                animateIn='fadeIn'
                offset={0}
                delay={150}
              >
                <TransformText>
                  Select your sector below to find out more about how
                  TrustFlight can streamline your&nbsp;business.
                </TransformText>
              </ScrollAnimation>
            </TransformerDiv>
            <Row className={styles.tabsRow}>
              <Col md={3}>
                <ScrollAnimation animateOnce animateIn='fadeIn' offset={0}>
                  <ClickableOption
                    selected={paneChoice === "operators"}
                    onClick={() => setPaneChoice("operators")}
                  >
                    Aircraft Operators
                  </ClickableOption>
                </ScrollAnimation>
              </Col>
              <Col md={3}>
                <ScrollAnimation
                  animateOnce
                  animateIn='fadeIn'
                  offset={0}
                  delay={100}
                >
                  <ClickableOption
                    selected={paneChoice === "mro"}
                    onClick={() => setPaneChoice("mro")}
                  >
                    MROs
                  </ClickableOption>
                </ScrollAnimation>
              </Col>
              <Col md={3}>
                <ScrollAnimation
                  animateOnce
                  animateIn='fadeIn'
                  offset={0}
                  delay={200}
                >
                  <ClickableOption
                    selected={paneChoice === "regulators"}
                    onClick={() => setPaneChoice("regulators")}
                  >
                    Regulators
                  </ClickableOption>
                </ScrollAnimation>
              </Col>
              <Col md={3}>
                <ScrollAnimation
                  animateOnce
                  animateIn='fadeIn'
                  offset={0}
                  delay={300}
                >
                  <ClickableOption
                    selected={paneChoice === "companies"}
                    onClick={() => setPaneChoice("companies")}
                  >
                    Leasing Companies
                  </ClickableOption>
                </ScrollAnimation>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <TabSection>
        <Container>
          <Row className='justify-content-md-center'>
            <Col xl={8} lg={10} md={10}>
              <DisplayOption choice={paneChoice} />
            </Col>
          </Row>
        </Container>
      </TabSection>

      <ScrollAnimation
        animateOnce
        animateIn='fadeIn'
        offset={0}
        delay={300}
      >
        <GlobalScale />
      </ScrollAnimation>
      <Container>
        <Row className={`justify-content-md-center ${styles.blogPostsParent}`}>
          <Col md={12}>
            <Row>
              {props.data.allMarkdownRemark.edges.map((edge, key) => (
                <Col md={3} sm={6}>
                  <ScrollAnimation
                    animateOnce
                    animateIn='fadeIn'
                    offset={0}
                    delay={key * 150}
                  >
                    <BlogDiv>
                      <Link
                        to={edge.node.frontmatter.slug}
                        className={styles.fullLink}
                      />
                      {edge.node.frontmatter.squareImage ||
                      edge.node.frontmatter.featuredImage ? (
                        <Fragment>
                          <Img
                            fluid={
                              edge.node.frontmatter.squareImage
                                ? edge.node.frontmatter.squareImage
                                    .childImageSharp.fluid
                                : edge.node.frontmatter.featuredImage
                                    .childImageSharp.fluid
                            }
                            className={styles.blogFeaturedImage}
                          />
                          <Img
                            fluid={
                              edge.node.frontmatter.featuredImage
                                ? edge.node.frontmatter.featuredImage
                                    .childImageSharp.fluid
                                : edge.node.frontmatter.squareImage
                                    .childImageSharp.fluid
                            }
                            className={styles.blogFeaturedImageFullHD}
                          />
                        </Fragment>
                      ) : (
                        <Fragment>
                          <Img
                            fluid={props.data.blogDefault.childImageSharp.fluid}
                            className={styles.blogFeaturedImage}
                          />
                          <Img
                            fluid={
                              props.data.blogDefaultFullHD.childImageSharp.fluid
                            }
                            className={styles.blogFeaturedImageFullHD}
                          />
                        </Fragment>
                      )}
                      <h3>{edge.node.frontmatter.title}</h3>
                    </BlogDiv>
                  </ScrollAnimation>
                </Col>
              ))}
            </Row>
            <Started />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query IndexQuery {
    mainImage: file(relativePath: { eq: "trustflight-featured.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    videoImage: file(relativePath: { eq: "tech-log@2x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 526) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jarImage: file(relativePath: { eq: "jar-featured.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 526) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blogDefault: file(relativePath: { eq: "Blog/blog_default_square.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 512) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blogDefaultFullHD: file(
      relativePath: { eq: "Blog/blog_default_fullhd.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 512) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog-post/" } }
      sort: { order: DESC, fields: frontmatter___date }
      limit: 4
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            date(formatString: "MMMM DD, YYYY")
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            squareImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
        }
      }
    }
  }
`;
